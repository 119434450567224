export function Lorem() {
    return (
        <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
            viverra vehicula lacinia. Maecenas congue ligula id dui tincidunt
            aliquet. Aenean tincidunt mi vitae dictum iaculis. Praesent lectus
            massa, condimentum vel rhoncus sit amet, accumsan vitae quam. Nullam
            sodales ultricies mauris, et pretium mi aliquam non. Donec
            scelerisque arcu id diam elementum tempus. Nullam nec bibendum ante,
            et semper tellus. Aenean fermentum nibh sit amet sagittis mattis.
        </div>
    )
}
